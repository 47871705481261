<template>
  <v-tooltip bottom v-if="tooltipText">
    <template v-slot:activator="{ on }">
      <v-btn icon @click="doCopy" v-on="on">
        <v-icon :size="iconSize">far fa-copy</v-icon>
      </v-btn>
    </template>
    <span>{{ tooltipText }}</span>
  </v-tooltip>
  <v-btn icon @click="doCopy" v-else>
    <v-icon :size="iconSize">far fa-copy</v-icon>
  </v-btn>
</template>

<script>
  export default {

    name: "ButtonIconCopy",

    props: {
      value: {type: String, default: ""},
      iconSize: {type: String, default: "16px"},
      showToast: {type: Boolean, default: true},
      tooltipText: { type: String, default: null }
    },

    methods: {
      doCopy() {
        this.$copyText(this.value)
        .then(() => {
          if (this.showToast) {
            this.$store.commit("toast/showSuccess", this.$t('common.buttons.copyBtn.success'));
          }
        },
        () => {
          if (this.showToast)
            this.$store.commit("toast/showError", this.$t('common.buttons.copyBtn.error'))
        });
      }
    }
  }
</script>

<style scoped>

</style>
